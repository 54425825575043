import React, { useEffect, useRef, useState } from 'react';
import { functions } from '@wap-client/core';
import type { TabsProps } from './types';
import { TabProps } from './tab/types';
import { motion } from 'framer-motion';
import useScrollWheel from '@/hooks/useScrollWheel';
import { scrollToCenter } from '@/utils';

const animations = {
  container: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
        delayChildren: 0.25,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Tabs: React.FC<TabsProps> = ({
  align = 'center',
  direction = 'row',
  className,
  testId,
  activeId,
  children,
  onChange,
}) => {
  const scrollableRef = useScrollWheel<HTMLDivElement>();
  const [scrolledLeft, setScrolledLeft] = useState(false);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const left = e.currentTarget.scrollLeft;
    const areaWidth = e.currentTarget.clientWidth;
    if (left > (areaWidth / 100) * 2) {
      setScrolledLeft(true);
    } else {
      setScrolledLeft(false);
    }
  };

  const onChangeWrapper = (event: React.MouseEvent, id: string) => {
    if (onChange) {
      onChange(id);
      // const target = event.target as HTMLElement;
      // scrollToCenter(target, '.tabs2-scrollable');
    }
  };

  const classNames = functions.classnames(
    'tabs2',
    `tabs2--direction-${direction}`,
    `tabs2--align-${align}`,
    `tabs2--scrolled-le-${align}`,
    className
  );

  useEffect(() => {
    const activeTabElement =
      scrollableRef.current?.querySelector('.tabs2-tab--active');
    if (activeTabElement) {
      scrollToCenter(activeTabElement as HTMLElement, '.tabs2-scrollable');
    }
  }, [activeId]);

  return (
    <div className={classNames} data-testid={testId}>
      <div
        className={functions.classnames('tabs2-left-right-gradient', {
          'tabs2-left-right-gradient--left-active': scrolledLeft,
        })}
      />
      <div ref={scrollableRef} className="tabs2-scrollable" onScroll={onScroll}>
        <motion.div
          className="tabs2-list"
          variants={animations.container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.1 }}
        >
          {React.Children.toArray(children).map((child) => {
            if (React.isValidElement(child)) {
              const childProps = child.props as TabProps;
              return React.cloneElement(child, {
                onClick: (event) => onChangeWrapper(event, childProps.id ?? ''),
                isActive: childProps.id === activeId,
              } as React.Attributes & Partial<TabProps>);
            }
            return child;
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default React.memo(Tabs);

import React from 'react';
import { functions } from '@wap-client/core';
import { TabProps } from './types';
import { motion } from 'framer-motion';

const animations = {
  tab: {
    hidden: {
      opacity: 0,
      x: 50,
      scale: 0,
    },
    show: {
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Tab: React.FunctionComponent<TabProps> = ({
  isActive,
  className,
  onClick,
  children,
}) => {
  const classNames = functions.classnames(
    'tabs2-tab',
    {
      'tabs2-tab--active': isActive,
    },
    className
  );

  return (
    <button className={classNames} onClick={onClick}>
      {children}
    </button>
  );
};

export default Tab;

import { RefObject, useEffect, useRef } from 'react';

const useScrollWheel = <T extends HTMLElement>(): RefObject<T> => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const onWheel = (e: WheelEvent) => {
      if (!ref.current) {
        return;
      }

      const hasVerticalScrollBar =
        ref.current.scrollHeight > ref.current.clientHeight;
      const hasHorizontalScrollBar =
        ref.current.scrollWidth > ref.current.clientWidth;

      if (!hasVerticalScrollBar && !hasHorizontalScrollBar) {
        return;
      }

      if (e.deltaY !== 0) {
        ref.current.scrollTo({
          left: ref.current.scrollLeft + e.deltaY,
          behavior: 'instant',
        });
        e.preventDefault();
      }
    };

    ref.current.addEventListener('wheel', onWheel, { passive: false });
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('wheel', onWheel);
      }
    };
  }, [ref.current]);

  return ref;
};

export default useScrollWheel;

import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import { ISliderCardsSlidersProps } from './types';
import React, { useMemo, useRef } from 'react';
import { Grid, Pagination, Navigation } from 'swiper/modules';
import Card from '@/widgets/card';
import 'swiper/css'; // Swiper CSS
import 'swiper/css/grid';
import 'swiper/css/pagination';
// import 'swiper/css/navigation';
import { UICard } from '@/components/widgets/card/types';
import SwiperToolbar from './toolbar/SwiperToolbar';
import useNanoId from '@/hooks/useNanoId';
import { motion, useInView } from 'framer-motion';
import CategoryCard from '@/components/widgets/card/category-card';
import { isEmpty } from 'lodash';
import card from '@/widgets/card';

const animations = {
  swiper: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.15,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const DEFAULT_SLIDER_SETTINGS: SwiperOptions = {
  modules: [Grid, Pagination, Navigation],
  slideToClickedSlide: false,
  breakpoints: {
    320: {
      slidesPerView: 'auto',
      spaceBetween: 8,
    },
    520: {
      slidesPerView: 'auto',
      spaceBetween: 20,
    },
  },
};

const SliderCardsSlider: React.FC<ISliderCardsSlidersProps> = ({
  sliderId,
  cards = [],
  siderToolbarInsideShow = true,
  sliderSettings = {},
  lightboxView,
  like,
  showTopList,
  poster,
}) => {
  const uniqueSliderId = useNanoId();
  const selectedSliderId = useMemo(
    () => (!isEmpty(sliderId) ? sliderId : uniqueSliderId),
    [sliderId]
  );
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  const completedSliderSettings = useMemo<SwiperProps>(() => {
    const selectors = {
      toolbar: `toolbar-${selectedSliderId}`,
      navigation: {
        prev: `prev-${selectedSliderId}`,
        next: `next-${selectedSliderId}`,
      },
      pagination: `pagination-${selectedSliderId}`,
    };

    return {
      ...DEFAULT_SLIDER_SETTINGS,
      ...sliderSettings,
      onInit: (swiper) => {
        if (selectors) {
          const findedToolbarEl = document.querySelector(
            `.${selectors.toolbar}`
          );

          if (findedToolbarEl) {
            findedToolbarEl.classList.add('swiper-initialized');
          }
        }
      },
      navigation: sliderSettings.navigation && {
        nextEl: selectors
          ? `.${selectors.navigation.next}`
          : '.swiper-toolbar-prev',
        prevEl: selectors
          ? `.${selectors.navigation.prev}`
          : '.swiper-toolbar-next',
        ...Object(sliderSettings.navigation),
      },
      pagination: sliderSettings.pagination && {
        el: selectors
          ? `.${selectors.pagination}`
          : '.swiper-toolbar-pagination',
        type: 'bullets',
        clickable: true,
        ...Object(sliderSettings.pagination),
      },
    };
  }, [selectedSliderId]);

  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <motion.div
      className="swiper-container"
      variants={animations.swiper}
      initial="hidden"
      animate={`${isInView ? 'show' : 'hidden'}`}
      ref={ref}
      viewport={{ once: true, amount: 0.25 }}
    >
      {poster?.image && (
        <div className="slider-card-poster">
          <div className="slider-card-poster-item">
            <CategoryCard {...poster} size="small" />
          </div>
        </div>
      )}

      <Swiper data-fav2={sliderId} {...completedSliderSettings}>
        {cards &&
          cards.length > 0 &&
          cards?.map((card: UICard, index: number) => (
            <SwiperSlide key={index}>
              <Card
                key={index}
                {...card}
                like={like}
                lightbox={lightboxView}
                lightboxGalleryId={selectedSliderId}
                index={index}
                showTopList={showTopList}
              />
            </SwiperSlide>
          ))}
      </Swiper>
      {siderToolbarInsideShow && (
        <SwiperToolbar
          sliderId={selectedSliderId}
          navigation={!!completedSliderSettings.navigation}
          pagination={!!completedSliderSettings.pagination}
        />
      )}
    </motion.div>
  );
};

export default SliderCardsSlider;

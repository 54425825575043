import React, { useMemo } from 'react';
import { ISwiperToolbarProps } from './types';
import Icon from '@/components/base/icon';
import { functions } from '@wap-client/core';
import { motion } from 'framer-motion';

const animations = {
  toolbar: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
        delayChildren: 0.3,
        staggerChildren: 0.15,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  navItem: {
    hidden: {
      opacity: 0,
      x: -80,
      scale: 0,
    },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  pagination: {
    hidden: {
      opacity: 0,
      x: -80,
      scale: 0,
    },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 0.2,
        delay: 0.3 + 0.15,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const SwiperToolbar: React.FC<ISwiperToolbarProps> = ({
  sliderId,
  pagination = true,
  navigation = true,
  className,
}) => {
  if (!navigation && !pagination) {
    return null;
  }

  const selectors = useMemo(() => {
    if (!sliderId) {
      return null;
    }

    return {
      toolbar: `toolbar-${sliderId}`,
      navigation: {
        prev: `prev-${sliderId}`,
        next: `next-${sliderId}`,
      },
      pagination: `pagination-${sliderId}`,
    };
  }, [sliderId]);

  return (
    <motion.div
      className={functions.classnames(
        'swiper-toolbar',
        className,
        selectors && selectors.toolbar
      )}
      variants={animations.toolbar}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
    >
      {navigation && (
        <>
          <motion.div
            className={functions.classnames(
              'swiper-toolbar-prev',
              selectors && selectors.navigation.prev
            )}
            variants={animations.navItem}
          >
            <Icon name="icon-chev-left" />
          </motion.div>
          <motion.div
            className={functions.classnames(
              'swiper-toolbar-next',
              selectors && selectors.navigation.next
            )}
            variants={animations.navItem}
          >
            <Icon name="icon-chev-right" />
          </motion.div>
        </>
      )}
      {pagination && (
        <motion.div
          className={functions.classnames(
            'swiper-toolbar-pagination',
            selectors && selectors.pagination
          )}
          variants={animations.pagination}
        />
      )}
    </motion.div>
  );
};

export default SwiperToolbar;

import { ISliderCardsProps } from './types';
import SliderCardsSlider from './slider-swiper/SliderCardsSlider';
import { functions } from '@wap-client/core';

const SliderCards: React.FC<ISliderCardsProps> = ({
  cards = [],
  sliderId,
  siderToolbarInsideShow,
  sliderSettings,
  lightboxView,
  like,
  poster,
  showTopList,
  className,
}) => {
  return (
    <div
      className={functions.classnames(
        'slider-cards',
        poster?.image && 'poster',
        className
      )}
    >
      <SliderCardsSlider
        sliderId={sliderId}
        cards={cards}
        siderToolbarInsideShow={siderToolbarInsideShow}
        sliderSettings={sliderSettings}
        lightboxView={lightboxView}
        like={like}
        poster={poster}
        showTopList={showTopList}
      />
    </div>
  );
};

export default SliderCards;

export const scrollToCenter = (
  target: HTMLElement,
  scrollableSelector: string
) => {
  if (target) {
    const scrollableEl = target.closest(scrollableSelector);

    if (scrollableEl) {
      const scrollableRect = scrollableEl.getBoundingClientRect();
      const targetRect = target.getBoundingClientRect();

      // Ortalamak için kaydırma pozisyonunu hesapla
      const scrollLeft =
        targetRect.left -
        scrollableRect.left +
        scrollableEl.scrollLeft -
        scrollableRect.width / 2 +
        targetRect.width / 2;

      scrollableEl.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }
};
